<template>
    <section class="o-section" :id="section.id " :class="['o-section--' + section.id, section.bg ? 'o-section--has-bg' : '']">
        <div class="o-section__bg" v-if="section.bg" :class="sectionBg(section.bg)" :id="'sectionBG-' + section.id"></div>
        <div class="o-container is-fullhd">
            <page-component v-for="(comp, index) in section.comps" :comp="comp" :key="index"/>
        </div>
    </section>
</template>

<script>
  import PageComponent from '@/components/PageComponent'

  export default {
    name: 'PageSection',
    props: {
      section: Object
    },

    methods: {
      sectionBg(color) {
        if (color) return 'o-section__bg--' + color.toLowerCase()
      }
    },

    components: {
      PageComponent
    }
  }
</script>
