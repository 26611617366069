<template>
    <main>
        <page-section v-for="(section, index) in sections" :section="section" :key="index"/>
    </main>
</template>

<script>
  import PageSection from '@/components/PageSection'
  import VueScrollTo from 'vue-scrollto'
  import {EventBus} from '@/event-bus'
  import axios from 'axios'

  export default {
    name: 'Home',

    metaInfo () {
      return {
          title: 'Müller Holzbearbeitung – Individuelle Reparaturen und Möbelprojekte',
          titleTemplate: 'Müller Holzbearbeitung – %s',
          links: [
            {rel: 'canonical', href: `${process.env.VUE_APP_BASE_URL}`}
          ],
          htmlAttrs: {
            lang: 'de'
          },
          meta: [
            // OpenGraph data (Most widely used)
            {
              property: 'og:title',
              content: 'Müller Holzbearbeitung – Individuelle Reparaturen und Möbelprojekte'
            },
            {property: 'og:site_name', content: 'Müller Holzbearbeitung'},
            // The list of types is available here: http://ogp.me/#types
            {property: 'og:type', content: 'website'},
            // Should the the same as your canonical link, see below.
            {property: 'og:url', content: `${process.env.VUE_APP_BASE_URL}`},
            {
              property: 'og:image',
              content: `${process.env.VUE_APP_BASE_URL}/static/mueller_ogp.jpg`
            },
            // Often the same as your meta description, but not always.
            {
              property: 'og:description',
              content: 'Individuelle Reparaturen und Möbelprojekte seit über 30 Jahren'
            }
          ]
        }
      },

    data() {
      return {
        sections: null,
      }
    },

    created() {
      this.getSections()
    },

    mounted() {
      EventBus.$once('pageLoaded', () => {
        if (this.$route.hash) {
          VueScrollTo.scrollTo(this.$route.hash, {
            easing: '[0.5, 1.0, 0.9, 1.0]',
            duration: 700
          })
        }
      })
    },

    beforeDestroy() {
      EventBus.$emit('pageLoaded', false)
    },

    methods: {
      getSections() {
        axios.get(process.env.VUE_APP_SECTIONS_URL)
            .then(res => {
              this.sections = res.data.entries
            })
            .catch(e => {
              /*eslint no-console: ["error", { allow: ["error"] }] */
              console.error(e)
            })
      }
    },

    components: {
      PageSection
    }
  }
</script>
