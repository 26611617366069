<template>
    <component :is="component" :comp="comp" :errors="errors" v-if="component"></component>
</template>

<script>
  import _ from 'lodash'

  export default {
    name: 'PageComponent',
    props: {
      comp: Object,
      errors: Array
    },

    data() {
      return {
        component: null
      }
    },

    mounted () {
      this.loader()
          .then(() => {
            this.component = () => this.loader()
          })
          .catch(e => {
            /*eslint no-console: ["error", { allow: ["error"] }] */
            console.error(e)
          })

    },

    computed: {
      loader () {
        let component = _.camelCase(this.comp.component)

        if (!this.comp.component) {
          return null
        }
        return () => import(/* webpackChunkName: "[request]" */ `@/components/global/Comp_${component}`)
      },
    }
  }
</script>
